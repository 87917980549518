import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { HlsJsVideoPlayer, PlayerType } from "@/components/video-player";
import VideoUploader from "@/components/video-uploader";
import { cdnUrl } from "@/lib/cdn";
import { getLowestQuality, VideoMetadata } from "@/lib/video";
import { Squirrel } from "lucide-react";
import { Suspense, useEffect } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";

import { AvailableQualityBadges } from "./common";

function UploadButton() {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button>Upload Video</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Choose a video to upload...</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="my-2">
          <VideoUploader />
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

interface VideoListProperties {
  videos: Array<VideoMetadata>;
}

function VideoList({ videos }: VideoListProperties) {
  const listItems = videos.map((video) => {
    const missingDescription = video.description.length === 0;
    const availableQualityBadges = AvailableQualityBadges(video) || (
      <span className="text-xs text-muted-foreground py-0.5 italic">
        This video is still processing...
      </span>
    );
    const lowestQuality = getLowestQuality(video);
    let preview = (
      <Skeleton className="rounded-md flex justify-center items-center w-full h-[180px]">
        <Squirrel className="dancing-squirrel" size={28} />
      </Skeleton>
    );

    if (lowestQuality !== null) {
      const playlistBasePath = video.playlistBasePaths[lowestQuality];

      if (playlistBasePath !== null) {
        const videoUrl = cdnUrl(`/${playlistBasePath}stream.m3u8`);
        preview = (
          <HlsJsVideoPlayer url={videoUrl} type={PlayerType.Thumbnail} />
        );
      } else {
        console.error(
          `Playlist base path for quality ${lowestQuality} was specified to be null.`,
        );
      }
    }

    return (
      <div
        className="video-manager-list-item border shadow-sm bg-card text-card-foreground rounded-md overflow-hidden flex flex-col"
        key={video.slug}
      >
        <div className="grow-1">
          <Link to={`/video-manager/${video.slug}`}>{preview}</Link>
        </div>
        <div className="grow-0 p-4">
          <Link to={`/video-manager/${video.slug}`}>
            <div>
              <h4 className="font-medium leading-none mb-1 truncate overflow-hidden">
                {video.title || "No Title"}
              </h4>
              <p
                className={
                  "text-xs text-muted-foreground text-ellipsis truncate overflow-hidden" +
                  (missingDescription ? " italic" : "")
                }
              >
                {video.description || "No Description"}
              </p>
            </div>
          </Link>
          <div className="grow-0 space-x-2 mt-4 align-middle">
            {availableQualityBadges}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="video-manager-list grid grid-cols-4 gap-4">{listItems}</div>
  );
}

function VideoManagerSuspense() {
  return (
    <div className="video-manager-list grid grid-cols-4 gap-4">
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
      <Skeleton className="rounded-md flex w-full h-[180px]" />
    </div>
  );
}

function VideoManagerPage() {
  useEffect(() => {
    document.title = "Video Manager";
  }, []);

  const { videos } = useLoaderData() as { videos: Array<VideoMetadata> };

  return (
    <main className="container pb-8">
      <header className="flex flex-row items-center justify-between">
        <h2 className="font-semibold text-2xl tracking-tight">Video Manager</h2>
        <UploadButton />
      </header>
      <Separator className="my-4 h-0" />
      <Suspense fallback={<VideoManagerSuspense />}>
        <Await resolve={videos} errorElement={<p>Failed to load videos.</p>}>
          {(videos) => <VideoList videos={videos} />}
        </Await>
      </Suspense>
    </main>
  );
}

export default VideoManagerPage;
